<template>
  <div id="routtle-box">
    <modal-draw-record ref="refModalRecord" />
    <div class="top_info">
      <h3 class="ticket">
        你擁有{{
          $store.state.app.user_info && $store.state.app.user_info.ticket
        }}張抽獎卷
      </h3>
      <a
        href="javascript:void(0)"
        @click="triggerModalDrawRecord"
        class="btn_record_list"
      >
        <font-awesome-icon icon="fa-solid fa-sheet-plastic" />
        <p>中獎記錄</p>
      </a>
    </div>
    <div class="anime_bg">

      <img
        v-if="isDrawEnd"
        src="@/assets/images/routtle/sunburst.0838324a.svg"
        alt=""
      />
    </div>
    <div class="slot">
      <div class="slot__outer">
        <div class="slot__inner">
          <template v-if="isDrawEnd && !isDisable">
            <h2 class="">{{ awards[prize - 1].reward_title }}</h2>
            <p class="mb-0">{{ awards[prize - 1].reward_description }}</p>
          </template>
        </div>
      </div>
    </div>
    <div class="routtle">
      <div
        class="routtle_outline"
        :class="{ running: loading, disable: isDisable }"
      >
        <div v-for="index of 16" :key="index" class="dot"></div>
        <div ref="ref_routtle" class="routtle_inline">
          <div
            v-for="award of awards"
            :key="'background' + award.reward_title + award.id"
            class="prize_background"
          ></div>
          <div class="prize">
            <div
              v-for="award of awards"
              :key="award.reward_title + award.id"
              class="prize-content"
            >
              {{ award.reward_title }}
            </div>
          </div>
        </div>
        <div class="button_block">
          <div class="button_wrapper">
            <div class="button_ouline">
              <div class="button_inline" @click="luckyStart">
                <span>{{ draw_text }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BModal, BButton, BCard, BRow, BCol } from "bootstrap-vue";
import axiosIns from "@/libs/axios";
import ModalDrawRecord from "./modal/ModalDrawRecord.vue";
export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    ModalDrawRecord,
  },
  data() {
    return {
      loading: false,
      isDrawEnd: false,
      draw_text: "開始抽獎",
      awards: [],
      // awards: [
      //   { title: "名貴跑車法拉利一部" },
      //   { title: "維他奶6包" },
      //   { title: "日本雙人來回機票" },
      //   { title: "多1天大假" },
      //   { title: "現金獎$10000" },
      //   { title: "跟老闆合影" },
      // ],
      prize: 0,
      isDisable: false,
    };
  },

  methods: {
    randomNumber(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    luckyStart() {
      if (!this.isDisable) {
        this.$swal({
          title: `你確定要使用1張抽獎卷嗎? \n你總共擁有${this.$store.state.app.user_info.ticket}張`,
          showCancelButton: true,
          confirmButtonText: "提交",
          cancelButtonText: "取消",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
            title: "swal2-title",
          },
          buttonsStyling: false,
        })
          .then((result) => {
            if (result.value) {
              this.$refs.ref_routtle.style =
                "transition: none; transform: rotate(15deg)";

              setTimeout(() => {
                this.loading = true;
                this.isDisable = true;

                axiosIns
                  .post("lucky_draw/draw")
                  .then((response) => {
                    this.draw_text = "正在抽獎";

                    this.prize = response.data.prize_id;
                    this.$refs.ref_routtle.style =
                      "transition: transform 4s ease-out; transform: rotate(" +
                      (1065 +
                        (this.prize - 1) * 60 +
                        this.randomNumber(1, 60)) +
                      "deg)";

                    setTimeout(() => {
                      // this.loading = false;
                      this.isDrawEnd = true;
                      this.isDisable = false;
                      this.draw_text = "開始抽獎";
                      this.$store.dispatch("app/fetchCoinsBalance");
                      this.$refs.refModalRecord.fetchRecord();
                    }, 4000);
                  })
                  .catch((error) => {
                    this.loading = false;
                    this.$swal({
                      text: JSON.stringify(error.response.data.message),
                      icon: "error",
                      confirmButtonText: "確定",
                      customClass: {
                        confirmButton: "btn btn-primary",
                      },
                      showClass: {
                        popup: "animate__animated animate__bounceIn",
                      },
                      buttonsStyling: false,
                    });
                  });
              }, 200);
            }
          })
          .catch((error) => {
            this.loading = false;
            this.$swal({
              text: JSON.stringify(error.response.data.message),
              icon: "error",
              confirmButtonText: "確定",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              showClass: {
                popup: "animate__animated animate__bounceIn",
              },
              buttonsStyling: false,
            });
          });
      }
    },
    fetchLuckyDraw() {
      axiosIns.get("/lucky_draw/list").then((response) => {
        this.awards = response.data.lucky_draw_lists;
      });
    },
   
    triggerModalDrawRecord() {
      this.$root.$emit("bv::show::modal", "modal-draw-record", "#btnShow");
    },
  },
  created() {
    this.fetchLuckyDraw();
  },
  mounted(){
    this.$refs.refModalRecord.fetchRecord();
  }
};
</script>


<style lang="scss">
.top_info {
  position: relative;
  z-index: 10;
  display: flex;
  width: 100%;
  padding: 3rem 1.5rem 0;
  justify-content: space-between;
  align-items: center;
  .ticket {
  }
  .btn_record_list {
    font-size: 1.2rem;
    &:hover,
    &:active {
      color: #469b2c;
    }
    svg {
      font-size: 2.2rem;
    }
  }
}
.swal2-title {
  font-size: 24px;
}
#routtle-box {
  position: relative;
  overflow: hidden;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  width: 100%;
  // margin: -3.5rem -2rem;
  min-height: 1000px;
  background: linear-gradient(
    143deg,
    #bf953f,
    #fcf6ba,
    #b38728,
    #fbf5b7,
    #aa771c
  );
  // background: linear-gradient(90deg, #469C2C 38.54%, #BBF84E 100%);
}
@keyframes fadeing1 {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeing2 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.2;
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.anime_bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
  width: 150%;
  aspect-ratio: 1/1;
  img {
    -webkit-animation: rotating 5s linear infinite;
    -moz-animation: rotating 5s linear infinite;
    -ms-animation: rotating 5s linear infinite;
    -o-animation: rotating 5s linear infinite;
    animation: rotating 5s linear infinite;
  }
}
.slot {
  position: relative;
  width: 100%;
  .slot__inner,
  .slot__outer {
    border-radius: 1.25rem;
    position: relative;
  }
  .slot__outer {
    background: #ffbf1f;
    // url(~@/assets/images/routtle/light-blubs.svg)
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0.625rem 0.625rem 0 rgb(0 0 0 / 20%);
    margin: 0 auto;
    height: 12.375rem;
    width: 53.5rem;
    padding: 1.625rem;
    z-index: 3;
    transform: scale(0.6);
  }
  .slot__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    height: 100%;
    width: 100%;
    overflow: hidden;
    color: #471247;
    h2 {
      font-size: 2.5rem;
      font-weight: bold;
    }
    p {
      font-size: 2rem;
    }
  }
}

.routtle {
  transform: scale(1.5);
  padding-top: 6rem;
  display: flex;
  justify-content: center;
  .routtle_outline {
    position: relative;
    border-radius: 100%;
    background-color: #ffbe04;
    border: 1px solid #fff;
    width: 360px;
    height: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 17px;
      height: 0px;
      width: 0px;
      border-style: solid;
      z-index: 2;
    }
    &:before {
      left: 179px;
      border-width: 0 0 30px 10px;
      border-color: transparent transparent #e62d2d transparent;
      transform: rotate(180deg);
    }
    &:after {
      right: 179px;
      border-width: 0 10px 30px 0px;
      border-color: transparent transparent #ca1518 transparent;
      transform: rotate(180deg);
    }
    &.disable {
      .button_block {
        .button_ouline {
          background: #e5e5e5;
          cursor: not-allowed;
          pointer-events: all !important;
          .button_inline {
            background: #e5e5e5;
            color: rgb(200, 200, 200);
          }
        }
      }
    }
    &:not(.disable) {
      .button_ouline {
        &:hover {
          background: #eb4d4d;
          .button_inline {
            color: #fede81;
            background: #eb4d4d;
          }
        }
      }
    }
    /* position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%); */

    &.running {
      .dot {
        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(5),
        &:nth-child(7),
        &:nth-child(9),
        &:nth-child(11),
        &:nth-child(13),
        &:nth-child(15) {
          -webkit-animation: fadeing1 0.4s linear infinite;
          -moz-animation: fadeing1 0.4s linear infinite;
          -ms-animation: fadeing1 0.4s linear infinite;
          -o-animation: fadeing1 0.4s linear infinite;
          animation: fadeing1 0.4s linear infinite;
        }
        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(6),
        &:nth-child(8),
        &:nth-child(10),
        &:nth-child(12),
        &:nth-child(14),
        &:nth-child(16) {
          -webkit-animation: fadeing2 0.4s linear infinite;
          -moz-animation: fadeing2 0.4s linear infinite;
          -ms-animation: fadeing2 0.4s linear infinite;
          -o-animation: fadeing2 0.4s linear infinite;
          animation: fadeing2 0.4s linear infinite;
        }
      }
    }
    .dot {
      position: absolute;
      background-color: #fff;
      border-radius: 100%;
      width: 10px;
      height: 10px;
      &:nth-child(1) {
        top: 18px;
        left: 105px;
      }
      &:nth-child(2) {
        top: 3px;
      }
      &:nth-child(3) {
        top: 20px;
        left: 247px;
      }
      &:nth-child(4) {
        top: 63px;
        right: 46px;
      }
      &:nth-child(5) {
        top: 124px;
        right: 12px;
      }
      &:nth-child(6) {
        top: 198px;
        right: 5px;
      }
      &:nth-child(7) {
        top: 258px;
        right: 25px;
      }
      &:nth-child(8) {
        bottom: 42px;
        right: 68px;
      }
      &:nth-child(9) {
        bottom: 13px;
        right: 123px;
      }

      &:nth-child(10) {
        bottom: 5px;
        right: 184px;
      }
      &:nth-child(11) {
        bottom: 19px;
        left: 105px;
      }
      &:nth-child(12) {
        bottom: 53px;
        left: 54px;
      }
      &:nth-child(13) {
        bottom: 104px;
        left: 19px;
      }
      &:nth-child(14) {
        bottom: 167px;
        left: 4px;
      }
      &:nth-child(15) {
        top: 120px;
        left: 12px;
      }
      &:nth-child(16) {
        top: 62px;
        left: 45px;
      }
    }

    .routtle_inline {
      position: relative;
      display: flex;
      justify-content: center;
      overflow: hidden;
      align-items: center;
      border-radius: 100%;
      width: 324px;
      height: 324px;
      border: 1px solid #fede81;

      transform: rotate(15deg);
      .prize_background {
        position: absolute;
        border-left: 95px solid transparent;
        border-right: 95px solid transparent;
        border-top: 164px solid;
        &:nth-child(1) {
          transform: rotate(45deg);
          left: 124px;
          top: 22px;
        }
        &:nth-child(2) {
          transform: rotate(104deg);
          left: 145px;
          top: 101px;
        }
        &:nth-child(3) {
          transform: rotate(163deg);
          left: 87px;
          top: 161px;
        }
        &:nth-child(4) {
          transform: rotate(224deg);
          left: 9px;
          top: 135px;
        }
        &:nth-child(5) {
          transform: rotate(282deg);
          left: -9px;
          top: 55px;
        }
        &:nth-child(6) {
          transform: rotate(342deg);
          top: -1px;
          left: 47px;
        }
        &:nth-child(2n + 0) {
          border-top-color: #fede81;
        }

        &:nth-child(2n + 1) {
          // border-top-color: #ffbe04;
          border-top-color: #fbbe04;
        }
      }
      .prize {
        .prize-content {
          position: absolute;
          width: 135px;
          height: 110px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          padding: 0 5px;
          box-sizing: border-box;
          &:nth-child(6) {
            transform: rotate(45deg);
            left: 170px;
            top: 27px;
          }
          &:nth-child(5) {
            transform: rotate(105deg);
            left: 204px;
            top: 135px;
          }
          &:nth-child(4) {
            transform: rotate(163deg);
            left: 123px;
            top: 212px;
          }
          &:nth-child(3) {
            transform: rotate(223deg);
            left: 14px;
            top: 188px;
          }
          &:nth-child(2) {
            transform: rotate(282deg);
            left: -16px;
            top: 78px;
          }
          &:nth-child(1) {
            transform: rotate(343deg);
            left: 64px;
            top: -2px;
          }
        }
      }
    }
    .button_block {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .button_wrapper {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        background-color: #ffbe04;
        width: 120px;
        height: 120px;
        z-index: 2;
      }
      .button_ouline {
        display: flex;
        background: #e62d2d;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        position: relative;
        width: 70%;
        height: 70%;
      }
      .button_inline {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        color: #ffbe04;
        font-size: 18px;
        background: #ca1518;
        width: 80%;
        height: 80%;
        flex-wrap: wrap;
        text-align: center;
        span {
          padding: 0 10px;
          line-height: 1.2;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .slot {
    .slot__outer {
      transform: scale(1);
      margin: 3rem 3rem 0;
      height: 10rem;
      width: calc(100% - 6rem);
      .slot__inner {
        h2 {
          font-size: 2rem;
        }
        p {
          font-size: 1.6rem;
        }
      }
    }
  }
  .routtle {
    transform: scale(1.3);
  }

}

@media screen and (max-width: 575px) {
  .routtle {
    transform: scale(0.95);
  }

  .slot {
    .slot__outer {
      width: calc(100% - 4rem);
      margin: 3rem 2rem 0;

      background-size: 100vw 10rem;
      .slot__inner {
        h2 {
          font-size: 1.5rem;
        }
        p {
          font-size: 1.2rem;
        }
      }
    }
  }
}
</style>